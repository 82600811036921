<template>
    <div class="clue-follow-page">
        <!-- <span @click="goback">返回</span> -->
        <el-row v-loading="loading" :gutter="10">
            <el-col :span="7">
                <el-card>
                    <div class="left-content flex-col">
                        <!-- 左侧头部线索信息 操作记录 -->
                        <div class="flex-sb title-tip">
                            <span class="title-tip-tit">线索记录</span>
                            <div class="title-tip-option" @click="operation(1)">操作记录</div>
                        </div>
                        <!-- 中间内容区域可滚动 -->
                        <div class="left-content-list flex-col">
                            <!--  -->
                            <div class="list-item">
                                <!-- 手机号 - 资金需求 -->
                                <div class="flex-sb flex" style="margin-bottom:10px">
                                    <div class="flex">
                                        <img src="@/assets/searchPage/phone.png" width="20" alt />
                                        <span
                                            class="mobile"
                                        >{{ common.hidePhoneNumber( clueInfo.mobileNumber ) }}</span>
                                    </div>
                                    <div v-if="clueInfo.fundNeeds">{{ clueInfo.fundNeeds }}万</div>
                                    <div v-else>未知</div>
                                </div>
                                <!-- 姓名 -->
                                <div class="flex-sb info-item">
                                    姓名：
                                    <div>{{ clueInfo.name ? clueInfo.name : "未知" }}</div>
                                </div>
                                <!-- 性别 -->
                                <div class="flex-sb info-item">
                                    性别：
                                    <div>{{ clueInfo.sex ? clueInfo.sex : "未知" }}</div>
                                </div>
                                <!-- 年龄 -->
                                <div class="flex-sb info-item">
                                    年龄：
                                    <div>{{ clueInfo.age ? clueInfo.age + "岁" : "未知" }}</div>
                                </div>

                                <!-- 居住地-->
                                <div class="flex-sb info-item">
                                    居住地：
                                    <div>{{ clueInfo.residence ? clueInfo.residence : "未知" }}</div>
                                </div>

                                <!-- 客户资质：-->
                                <div class="flex-sb info-item">
                                    客户资质：
                                    <div v-if="!clueInfo.isALlNone">
                                        <span
                                            v-if="clueInfo.hasHouse"
                                            class="icon house"
                                            style="margin-right: 10px"
                                        >房</span>
                                        <span
                                            v-if="clueInfo.hasVehicle"
                                            class="icon car"
                                            style="margin-right: 10px"
                                        >车</span>
                                        <span
                                            v-if="clueInfo.hasShebao"
                                            style="margin-right: 10px"
                                            class="icon shebao"
                                        >社</span>
                                        <span
                                            v-if="clueInfo.hasGongjijin"
                                            class="icon gongjijin"
                                            style="margin-right: 10px"
                                        >金</span>
                                        <span v-if="clueInfo.hasBaodan" class="icon baodan">保</span>
                                    </div>
                                    <div v-else>未知</div>
                                </div>
                                <!-- 来源渠道 -->
                                <div class="remark">
                                    <div class="re-title">来源渠道（{{ clueInfo.source }}）</div>
                                    <div>
                                        <div>
                                            <div
                                                class="item-remark"
                                            >话术提醒：{{ clueInfo.itescriptPrompt ? clueInfo.itescriptPrompt : "暂无" }}</div>
                                        </div>
                                        <div>
                                            <div
                                                class="item-remark"
                                            >线索备注：{{ clueInfo.remarks ? clueInfo.remarks : "暂无" }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="buttonCallPhone">
                                    <!-- <div class="buttonCALL" @click="callPhone">拨打电话</div> -->
                                    <el-button
                                        v-if="callServices.isEnabled()"
                                        type="primary"
                                        :disabled="call_SatausChange"
                                        @click="callPhone"
                                    >拨打电话</el-button>
                                </div>
                            </div>
                            <!-- 剩余跟进时间 转移 -->
                            <div class="flex-sb flex time-change">
                                <div>
                                    <span style="color:#7F7F7F">剩余跟进时间：</span>
                                    <time style="color:#D9001B">{{ clueInfo.restTime }}</time>
                                </div>
                                <el-button
                                    v-if="showTransBtn"
                                    type="warning"
                                    size="small"
                                    @click="transfer()"
                                >转移</el-button>
                            </div>
                        </div>
                        <!-- 返回 上一条 下一条 -->
                        <div class="left-btn flex-sb flex">
                            <div class="flex back" @click="goback">
                                <img
                                    style="margin-right:5px"
                                    src="@/assets/common/back.png"
                                    width="20"
                                    height="20"
                                    alt
                                />
                                <span>返回列表</span>
                            </div>
                            <div v-if="searchPage.tableName">
                                <el-button
                                    plain
                                    size="small"
                                    :disabled="previousOrNext.previous == null"
                                    @click="lastPage"
                                >上一条</el-button>
                                <el-button
                                    plain
                                    size="small"
                                    :disabled="previousOrNext.next == null"
                                    @click="nextPage"
                                >下一条</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="17">
                <el-card>
                    <div class="right-content flex-col">
                        <!-- 左侧头部线索信息 操作记录 -->
                        <div class="flex-sb title-tip">
                            <div>
                                <span class="title-tip-tit">
                                    {{ clueInfo.newClient == 1 ? "新客户" : "老客户" }}
                                    <font
                                        v-if="clueInfo.newClient == 0"
                                    >({{ "ID：" + clueInfo.client.id }})</font>
                                </span>

                                <i
                                    v-if="clueInfo.important == 1"
                                    class="iconfont icon-aixin xin ping"
                                />
                            </div>
                            <div
                                v-if="clueInfo.newClient == 0"
                                class="title-tip-option"
                                @click="operation(2)"
                            >历史记录</div>
                        </div>
                        <div class="right-content-list">
                            <div class="title-font">
                                <span>基本信息</span>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="同步线索信息"
                                    placement="right"
                                >
                                    <img
                                        class="base-info"
                                        src="@/assets/common/synchroData.svg"
                                        alt
                                        @click="getLeftData(1)"
                                    />
                                </el-tooltip>
                            </div>
                            <el-form
                                ref="clueForm"
                                :model="clueForm"
                                :rules="rules"
                                label-width="90px"
                            >
                                <el-row>
                                    <el-col :span="6">
                                        <el-form-item label="姓名：" prop="name" class="prop-center">
                                            <el-input
                                                v-model="clueForm.name"
                                                style="min-width:114px"
                                                size="small"
                                                placeholder="请输入内容"
                                                clearable
                                            />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="性别：" prop="sex" class="prop-center">
                                            <el-radio-group
                                                v-model="clueForm.sex"
                                                size="mini"
                                                style="width:144px"
                                            >
                                                <el-radio-button
                                                    v-for="item in sexArr"
                                                    :key="item.number"
                                                    :value="item.number"
                                                    :label="item.number"
                                                >{{ item.cnVal }}</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="年龄：" prop="age" class="prop-center">
                                            <el-input
                                                v-model="clueForm.age"
                                                size="small"
                                                oninput="value=value.replace(/[^\d]/g,'')"
                                                placeholder="请输入数字"
                                                style="min-width:120px"
                                                clearable
                                            >
                                                <template slot="append">岁</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item
                                            label="居住地:"
                                            prop="residence"
                                            class="prop-center"
                                        >
                                            <el-cascader
                                                v-model="clueForm.residence"
                                                clearable
                                                size="mini"
                                                :show-all-levels="false"
                                                :options="areasOption"
                                                :props="{expandTrigger: 'click',label: 'name', value: 'name'}"
                                            />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <div class="title-font">
                                    <span>贷款需求</span>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        content="同步线索信息"
                                        placement="right"
                                    >
                                        <img
                                            class="base-info"
                                            src="@/assets/common/synchroData.svg"
                                            alt
                                            @click="getLeftData(2)"
                                        />
                                    </el-tooltip>
                                </div>
                                <el-row>
                                    <el-col :span="6">
                                        <el-form-item
                                            label="资金需求："
                                            prop="fundNeeds"
                                            class="prop-center"
                                        >
                                            <el-input
                                                v-model="clueForm.fundNeeds"
                                                clearable
                                                size="small"
                                                oninput="value=value.replace(/[^\d]/g,'')"
                                                placeholder="请输入数字"
                                                style="width:140px"
                                            >
                                                <template slot="append">万</template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <div class="title-font">
                                    <span>资质</span>
                                </div>

                                <!-- 车辆 -->
                                <el-row style="margin-top:10px">
                                    <el-col :span="4" class="switch-area">
                                        <el-switch
                                            v-model="clueForm.vehicle.has"
                                            active-text="车辆"
                                            :active-value="1"
                                            :inactive-value="0"
                                        />
                                    </el-col>
                                    <el-col :span="20" class="info-area">
                                        <div v-if="clueForm.vehicle.has == 1">
                                            <el-col
                                                :span="8"
                                                style="text-align:center;padding: 6px 0;"
                                                prop="house.marketPrice"
                                            >
                                                <el-form-item
                                                    label="裸车价："
                                                    prop="vehicle.nakedPrice"
                                                    style="margin-bottom:0px"
                                                >
                                                    <el-input
                                                        v-model="clueForm.vehicle.nakedPrice"
                                                        style="min-width:150px"
                                                        size="mini"
                                                        placeholder="请输入数字"
                                                        oninput="value=value.replace(/[^\d]/g,'')"
                                                    >
                                                        <template slot="append">万</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col
                                                :span="7.5"
                                                style="text-align:center;padding: 6px 0;"
                                            >
                                                <el-form-item
                                                    label="付款："
                                                    style="margin-bottom:0px"
                                                    prop="vehicle.paymentType"
                                                >
                                                    <el-radio-group
                                                        v-model="clueForm.vehicle.paymentType"
                                                        size="mini"
                                                        style="min-width: 130px;"
                                                    >
                                                        <el-radio-button
                                                            v-for="item in carPaymentType"
                                                            :key="item.number"
                                                            :value="item.number"
                                                            :label="item.number"
                                                        >{{ item.cnVal }}</el-radio-button>
                                                    </el-radio-group>
                                                </el-form-item>
                                            </el-col>
                                        </div>
                                        <div
                                            v-else
                                            style="text-align:center;height:50px;line-height:50px;color:#ccc"
                                        >暂 无 内 容</div>
                                    </el-col>
                                </el-row>

                                <!-- 住房 -->
                                <el-row>
                                    <el-col :span="4" class="switch-area">
                                        <el-switch
                                            v-model="clueForm.house.has"
                                            active-text="房产"
                                            :active-value="1"
                                            :inactive-value="0"
                                        />
                                    </el-col>
                                    <el-col :span="20" class="info-area">
                                        <div v-if="clueForm.house.has == 1">
                                            <el-col
                                                :span="8"
                                                style="text-align:center;padding: 6px 0;"
                                                prop="house.marketPrice"
                                            >
                                                <el-form-item
                                                    label="房价："
                                                    prop="house.marketPrice"
                                                    style="margin-bottom:0px"
                                                >
                                                    <el-input
                                                        v-model="clueForm.house.marketPrice"
                                                        size="mini"
                                                        style="min-width:150px"
                                                        placeholder="请输入数字"
                                                        oninput="value=value.replace(/[^\d]/g,'')"
                                                    >
                                                        <template slot="append">万</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col
                                                :span="8"
                                                style="text-align:center;padding: 6px 0;"
                                            >
                                                <el-form-item
                                                    label="付款："
                                                    style="margin-bottom:0px"
                                                    prop="house.paymentType"
                                                >
                                                    <el-radio-group
                                                        v-model="clueForm.house.paymentType"
                                                        style="width:100%;text-align:left"
                                                        size="mini"
                                                    >
                                                        <el-radio-button
                                                            v-for="item in carPaymentType"
                                                            :key="item.number"
                                                            :value="item.number"
                                                            :label="item.number"
                                                        >{{ item.cnVal }}</el-radio-button>
                                                    </el-radio-group>
                                                </el-form-item>
                                            </el-col>
                                            <el-col
                                                :span="8"
                                                style="text-align:center;padding: 6px 0;"
                                            >
                                                <el-form-item
                                                    label="类型："
                                                    style="margin-bottom:0px"
                                                    prop="house.type"
                                                >
                                                    <el-select
                                                        v-model="clueForm.house.type"
                                                        size="mini"
                                                        placeholder="请选择"
                                                    >
                                                        <el-option
                                                            v-for="item in houseType"
                                                            :key="item.number"
                                                            :label="item.cnVal"
                                                            :value="item.number"
                                                        />
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                        </div>
                                        <div
                                            v-else
                                            style="text-align:center;height:50px;line-height:50px;color:#ccc"
                                        >暂 无 内 容</div>
                                    </el-col>
                                </el-row>

                                <!-- 公积金 -->
                                <el-row>
                                    <el-col :span="4" class="switch-area">
                                        <el-switch
                                            v-model="clueForm.gongjijin.has"
                                            active-text="公积金"
                                            :active-value="1"
                                            :inactive-value="0"
                                        />
                                    </el-col>
                                    <el-col :span="20" class="info-area">
                                        <div v-if="clueForm.gongjijin.has == 1">
                                            <el-col
                                                :span="8"
                                                style="text-align:center;padding: 6px 0;"
                                            >
                                                <el-form-item
                                                    label="月缴："
                                                    style="margin-bottom:0px"
                                                    prop="gongjijin.monthlyFee"
                                                >
                                                    <el-input
                                                        v-model="clueForm.gongjijin.monthlyFee"
                                                        style="min-width:150px"
                                                        size="mini"
                                                        placeholder="请输入数字"
                                                        oninput="value=value.replace(/[^\d]/g,'')"
                                                    >
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col
                                                :span="8"
                                                style="text-align:center;padding: 6px 0;"
                                            >
                                                <el-form-item
                                                    label="年限："
                                                    style="margin-bottom:0px"
                                                    prop="gongjijin.payYears"
                                                >
                                                    <el-input
                                                        v-model="clueForm.gongjijin.payYears"
                                                        size="mini"
                                                        style="min-width:150px"
                                                        placeholder="请输入数字"
                                                        oninput="value=value.replace(/[^\d]/g,'')"
                                                    >
                                                        <template slot="append">年</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col
                                                :span="8"
                                                style="text-align:center;padding: 6px 0;"
                                            >
                                                <el-form-item
                                                    label="类型："
                                                    style="margin-bottom:0px"
                                                    prop="gongjijin.type"
                                                >
                                                    <el-radio-group
                                                        v-model="clueForm.gongjijin.type"
                                                        size="mini"
                                                    >
                                                        <el-radio-button
                                                            v-for="item in gongjijin"
                                                            :key="item.number"
                                                            v-model="clueForm.gongjijin.type"
                                                            :value="item.number"
                                                            :label="item.number"
                                                        >{{ item.cnVal }}</el-radio-button>
                                                    </el-radio-group>
                                                </el-form-item>
                                            </el-col>
                                        </div>
                                        <div
                                            v-else
                                            style="text-align:center;height:50px;line-height:50px;color:#ccc"
                                        >暂 无 内 容</div>
                                    </el-col>
                                </el-row>
                                <!-- 社保 -->
                                <el-row>
                                    <el-col :span="4" class="switch-area">
                                        <el-switch
                                            v-model="clueForm.shebao.has"
                                            active-text="社保"
                                            :active-value="1"
                                            :inactive-value="0"
                                        />
                                    </el-col>
                                    <el-col :span="20" class="info-area">
                                        <div v-if="clueForm.shebao.has == 1">
                                            <el-col
                                                :span="8"
                                                style="text-align:center;padding: 6px 0;"
                                            >
                                                <el-form-item
                                                    label="年限："
                                                    style="margin-bottom:0px"
                                                    prop="shebao.payYears"
                                                >
                                                    <el-input
                                                        v-model="clueForm.shebao.payYears"
                                                        style="min-width:150px"
                                                        size="mini"
                                                        placeholder="请输入数字"
                                                        oninput="value=value.replace(/[^\d]/g,'')"
                                                    >
                                                        <template slot="append">年</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col
                                                :span="8"
                                                style="text-align:center;padding: 6px 0;"
                                            >
                                                <el-form-item
                                                    label="类型："
                                                    style="margin-bottom:0px"
                                                    prop="shebao.type"
                                                >
                                                    <el-select
                                                        v-model="clueForm.shebao.type"
                                                        size="mini"
                                                        placeholder="请选择"
                                                    >
                                                        <el-option
                                                            v-for="item in shebao"
                                                            :key="item.number"
                                                            :label="item.cnVal"
                                                            :value="item.number"
                                                        />
                                                    </el-select>
                                                </el-form-item>
                                            </el-col>
                                        </div>
                                        <div
                                            v-else
                                            style="text-align:center;height:50px;line-height:50px;color:#ccc"
                                        >暂 无 内 容</div>
                                    </el-col>
                                </el-row>
                                <!-- 保单 -->
                                <el-row style="margin-bottom:20px">
                                    <el-col
                                        :span="4"
                                        class="switch-area"
                                        style="border-bottom: 1px solid #ccc;"
                                    >
                                        <el-switch
                                            v-model="clueForm.baodan.has"
                                            active-text="保单"
                                            :active-value="1"
                                            :inactive-value="0"
                                        />
                                    </el-col>
                                    <el-col
                                        :span="20"
                                        class="info-area"
                                        style="border-bottom: 1px solid #ccc;"
                                    >
                                        <div v-if="clueForm.baodan.has == 1">
                                            <el-col
                                                :span="8"
                                                style="text-align:center;padding: 6px 0;"
                                            >
                                                <el-form-item
                                                    label="年缴："
                                                    prop="baodan.annualFee"
                                                    style="margin-bottom:0px"
                                                >
                                                    <el-input
                                                        v-model="clueForm.baodan.annualFee"
                                                        style="min-width:150px"
                                                        size="mini"
                                                        placeholder="请输入数字"
                                                        oninput="value=value.replace(/[^\d]/g,'')"
                                                    >
                                                        <template slot="append">元</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col
                                                :span="7"
                                                style="text-align:center;padding: 6px 0;"
                                            >
                                                <el-form-item
                                                    label="年限："
                                                    style="margin-bottom:0px;"
                                                    prop="baodan.payYears"
                                                >
                                                    <el-input
                                                        v-model="clueForm.baodan.payYears"
                                                        style="min-width:150px"
                                                        size="mini"
                                                        placeholder="请输入数字"
                                                        oninput="value=value.replace(/[^\d]/g,'')"
                                                    >
                                                        <template slot="append">年</template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                        </div>
                                        <div
                                            v-else
                                            style="text-align:center;height:50px;line-height:50px;color:#ccc"
                                        >暂 无 内 容</div>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </div>
                        <div>
                            <el-row style="margin-top: 20px;height:auto">
                                <el-col :span="24">
                                    <div>
                                        <el-input
                                            v-model="clueForm.followContent"
                                            type="textarea"
                                            :autosize="{ minRows: 4, maxRows: 5 }"
                                            placeholder="请输入本次跟进内容"
                                            maxlength="128"
                                            show-word-limit
                                        />
                                    </div>
                                </el-col>
                            </el-row>
                            <div style="text-align:center;margin-top:10px">
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="saveClientOrFollow"
                                >{{ clueInfo.newClient == 1 ? "保存我的客户" : "保存跟进内容" }}</el-button>
                                <el-button
                                    class="normal"
                                    size="small"
                                    @click="saveUnconfirmed"
                                >保存为未确认线索</el-button>
                                <el-button class="normal" size="small" @click="saveUnuse">保存为无效线索</el-button>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <!-- 转移组件 -->
        <assign-dialog
            :title="title"
            :show-dialog.sync="showDialog"
            @submitPartment="submitPartment"
        />
        <clue-feedback
            ref="clueFeedback"
            @submitFollow="submitFollow"
            @saveUnsure="saveUnsure"
            @saveUnusertag="saveUnusertag"
        />
        <operation-info ref="operationInfo" :person-clue-data="getCallRecords" />
    </div>
</template>

<script>
// import {
// 	personClueData
// } from "../../assets/js/falseData.js";

// 转移线索- 上一页下一页- 保存跟进
import {
    transfer_clue,
    clueAround,
    clue_followed_log,
    addClueExpire, // 延长线索过期时间
} from '@/api/my_clue.js';
import areas from '@/assets/js/areaServices';
import { clue } from '@/api/my_clue';
let currentSession = '';
export default {
    inject: ['callPhonechange'],
    data() {
        return {
            // personClueData,
            call_SatausChange: false, //电话按钮
            clueInfo: {
                client: {
                    id: '',
                },
            },
            clueForm: {
                name: '',
                sex: '',
                age: '',
                residence: '',
                fundNeeds: '',
                followContent: '',
                house: {
                    has: 0,
                    marketPrice: '',
                    type: '',
                    paymentType: '',
                },
                vehicle: {
                    has: 0,
                    nakedPrice: '',
                    paymentType: '',
                },
                shebao: {
                    has: 0,
                    type: '',
                    payYears: '',
                },
                gongjijin: {
                    has: 0,
                    type: '',
                    payYears: '',
                    monthlyFee: '',
                },
                baodan: {
                    has: 0,
                    annualFee: '',
                    payYears: '',
                },
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur',
                    },
                    {
                        min: 1,
                        max: 32,
                        message: '长度是1-32个字符之间',
                        trigger: 'blur',
                    },
                    {
                        validator: this.formValidation.name,
                        trigger: 'blur',
                    },
                ],
                age: [
                    {
                        validator: this.formValidation.ldq,
                        trigger: 'blur',
                    },
                ],
                fundNeeds: [
                    {
                        validator: this.formValidation.ldyw,
                        trigger: 'change',
                    },
                ],
                vehicle: {
                    nakedPrice: [
                        {
                            validator: this.formValidation.ldyw,
                            trigger: 'change',
                        },
                    ],
                },
                house: {
                    marketPrice: [
                        {
                            validator: this.formValidation.ldyw,
                            trigger: 'blur',
                        },
                    ],
                },
                gongjijin: {
                    monthlyFee: [
                        {
                            validator: this.formValidation.ldsw,
                            trigger: 'blur',
                        },
                    ],
                    payYears: [
                        {
                            validator: this.formValidation.ldq,
                            trigger: 'blur',
                        },
                    ],
                },
                shebao: {
                    payYears: [
                        {
                            validator: this.formValidation.ldq,
                            trigger: 'blur',
                        },
                    ],
                },
                baodan: {
                    annualFee: [
                        {
                            validator: this.formValidation.ldyy,
                            trigger: 'blur',
                        },
                    ],
                    payYears: [
                        {
                            validator: this.formValidation.ldq,
                            trigger: 'blur',
                        },
                    ],
                },
            },
            clueId: '',
            sexArr: [],
            areasOption: [],
            purposeArr: [],
            carPaymentType: [],
            houseType: [],
            gongjijin: [],
            shebao: [],
            baodan: [],
            title: '选择要转移的对象',
            showDialog: false,
            previousOrNext: {
                previous: '',
                next: '',
            },
            searchPage: {},
            loading: false,
            showTransBtn: sessionStorage.getItem('showTransBtn'),
            getCallRecords: [], //录音线索
            clueDisabled: null,
            eed: '',
        };
    },
    components: {
        assignDialog: () => import('../client/components/assignDialog'),
        clueFeedback: () => import('./followComponent/clue-feedback'),
        operationInfo: () => import('../clue/followComponent/operation-info'),
    },
    mounted() {
        this.$EventBus.$on('CallEventName_2', this.callEventNameHandle);
    },
    watch: {},
    created() {
        this.sexArr = this.common.allValueNeed('sex');
        this.carPaymentType = this.common.allValueNeed('vehicle-pay-type');
        this.houseType = this.common.allValueNeed('house-type');
        this.gongjijin = this.common.allValueNeed('gongjijin-pay-type');
        this.shebao = this.common.allValueNeed('shebao-type');
        this.baodan = this.common.allValueNeed('loan-purpose-level');
        this.areasOption = areas.getAll();
    },
    beforeDestory() {
        this.$EventBus.$off('CallEventName_2', this.callEventNameHandle);
    },
    destroyed() {
        clearInterval(window.setOutTime);
    },

    methods: {
        // 拨打电话
        callPhone() {
            // this.call_SatausChange = true
            console.log(this.call_SatausChange, 'call_SatausChange');
            // this.$store.commit('callButtonClueChange', true)
            this.$store.commit('callJudge', true);
            this.callPhonechange();
            this.addClueExpire();
        },
        // 延长线索时间
        addClueExpire() {
            addClueExpire(this.clueId).then((res) => {
                if (res.code !== '0') return console.log(res.message);
                this.data.expiredTime = res.data.expireTime;
            });
        },

        // 数据处理
        getTreeData(data) {
            // 循环遍历json数据
            for (let i = 0; i < data.length; i++) {
                if (data[i].children.length < 1) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        },
        // 初始化
        init(row) {
            this.getClueInfo(row.id);
            this.searchPage = row;
            console.log(row.tableName);

            console.log(row, 'row');
            if (row.tableName) this.getPreviousOrNext(row);
        },
        // 返回事件
        goback() {
            // clearInterval(this.setOutTime);
            this.$emit('closeFollow');
        },
        // 获得详情
        getClueInfo(id) {
            this.clueId = id;
            // 请求接口
            clue({
                clueId: id,
            }).then((res) => {
                if (res.code !== '0') {
                    this.$message.error(res.message);
                    // this.$router.push('/my-clue')
                    return;
                }
                // console.log(res, 'clueall')
                if (res.code == 0) {
                    this.loading = true;
                    let data = res.data;
                    if (
                        data.hasBaodan == 0 &&
                        data.hasGongjijin == 0 &&
                        data.hasHouse == 0 &&
                        data.hasShebao == 0 &&
                        data.hasVehicle == 0
                    ) {
                        data.isALlNone = true;
                    } else {
                        data.isALlNone = false;
                    }

                    // 这边做姓名字典处理

                    let sex = this.common.allValueNeed('sex');
                    let c;
                    if (Array.isArray(sex)) {
                        c = sex.find((item) => {
                            return data.sex == item.number;
                        });
                    }
                    data.sex = c.cnVal;
                    data.restTime = this.common.InitTime(data.expiredTime);
                    // console.log(this.common.InitTime(data.expiredTime),'dsad')
                    window.setOutTime = setInterval(() => {
                        data.restTime = this.common.InitTime(data.expiredTime);
                        let outTime =
                            parseInt(data['expiredTime']) -
                            new Date().getTime() / 1000;
                        // console.log(outTime);
                        if (outTime < 0) {
                            clearInterval(window.setOutTime);
                            this.$message.warning(data.name + '线索已超时');
                            this.goback();
                        }
                    }, 1000);

                    this.clueInfo = data;

                    //UUID码
                    // let uuid = ''
                    // uuid = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                    // let callUUid = ''
                    // callUUid = (uuid + uuid + "-" + uuid + "-" + uuid + "-" + uuid + "-" + uuid + uuid + uuid)
                    function guid() {
                        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
                            /[xy]/g,
                            function (c) {
                                let r = (Math.random() * 16) | 0,
                                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                                return v.toString(16);
                            }
                        );
                    }

                    let callUUid = guid();

                    // 通话传入header的参数
                    let callParams = {
                        phoneNumber: this.common.hidePhoneNumber(
                            data.mobileNumber,
                            1
                        ),
                        clueId: this.clueId,
                        clientId: data.client.id,
                        callUUID: callUUid,
                        // followSaved: '',
                        calledName: data.name,
                        token: sessionStorage.getItem('sipToken'),
                    };

                    this.$store.commit('callSatausChange', callParams);

                    console.log(this.clueInfo, 'clueInfo');

                    if (data.client) {
                        this.clueForm = data.client;
                    }
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);

                    // 录音线索
                    this.getCallRecords = data.callLogs;
                    // console.log(this.getCallRecords, 'this.getCallRecords')
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获得上一页下一页的数据
        getPreviousOrNext(row) {
            let data = {
                clueId: row.id,
                tableName: row.tableName,
                qualityType: row.qualityType,
                fundNeedsScopeType: row.qualityType,
                status: row.status,
                statusTag: row.statusTag,
                newClient: row.newClient,
            };
            // 获得页面上一页下一页的id
            clueAround(data).then((res) => {
                if (res.code == 0) {
                    this.previousOrNext = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 同步数据
        getLeftData(type) {
            if (type == 1) {
                this.clueForm.name = this.clueInfo.name;
                let sexVal = '';
                let sex = this.common.allValueNeed('sex');
                sex.forEach((item) => {
                    if (item.cnVal == this.clueInfo.sex) {
                        sexVal = item.number;
                    }
                });
                this.clueForm.sex = sexVal;
                this.clueForm.age = this.clueInfo.age;
                this.clueForm.residence = this.clueInfo.residence;
            }
            if (type == 2) {
                this.clueForm.fundNeeds = this.clueInfo.fundNeeds;
            }
        },
        clearClueForm() {
            this.$refs.clueForm.resetFields();
            this.clueForm.vehicle.has = 0;
            this.clueForm.house.has = 0;
            this.clueForm.shebao.has = 0;
            this.clueForm.gongjijin.has = 0;
            this.clueForm.baodan.has = 0;
            this.clueForm.followContent = '';
        },
        // 上一页
        lastPage() {
            clearInterval(window.setOutTime);
            // this.getClueInfo(this.previousOrNext.previous);
            let data = {
                id: this.previousOrNext.previous,
                tableName: this.searchPage.tableName,
                qualityType: this.searchPage.qualityType,
                fundNeedsScopeType: this.searchPage.qualityType,
                status: this.searchPage.status,
                statusTag: this.searchPage.statusTag,
                newClient: this.searchPage.newClient,
            };
            this.searchPage.id = this.previousOrNext.previous;
            this.getClueInfo(this.previousOrNext.previous);
            this.getPreviousOrNext(data);
            this.clearClueForm();
        },
        // 下一页
        nextPage() {
            clearInterval(window.setOutTime);
            // this.$refs.clueForm.resetFields();
            console.log(this.previousOrNext.next);
            this.searchPage.id = this.previousOrNext.next;
            let data = {
                id: this.previousOrNext.next,
                tableName: this.searchPage.tableName,
                qualityType: this.searchPage.qualityType,
                fundNeedsScopeType: this.searchPage.qualityType,
                status: this.searchPage.status,
                statusTag: this.searchPage.statusTag,
                newClient: this.searchPage.newClient,
            };
            this.getClueInfo(this.previousOrNext.next);
            this.getPreviousOrNext(data);
            this.clearClueForm();
        },
        // 转移
        transfer() {
            this.showDialog = true;
        },
        // 选完部门人员后点击确定事件
        submitPartment(val) {
            // 线索转移接口调用
            transfer_clue({
                clueId: this.clueId,
                staffId: val,
            }).then((res) => {
                if (res === undefined) return this.$message.error('权限不足');
                if (res.code == 0) {
                    this.$message({
                        message: '已将线索转移',
                        type: 'success',
                    });
                    this.showDialog = false;
                    // 调用下一条
                    if (this.previousOrNext.next) {
                        this.nextPage();
                    } else {
                        // 如果下一页的id存在就跳到下一页没有的话关闭
                        this.goback();
                    }
                } else {
                    this.$message.error('参数不可为空');
                }
            });
        },
        // 保存我的客户
        saveClientOrFollow() {
            this.$refs.clueForm.validate((valid) => {
                if (valid) {
                    // 显示线索弹框
                    this.$refs.clueFeedback.init(1);
                } else {
                    return false;
                }
            });
        },
        // 保存为未确认
        saveUnconfirmed() {
            // 显示线索弹框
            // this.$refs.clueForm.resetFields();
            this.clearClueForm();
            this.$refs.clueFeedback.init(2);
        },
        // 保存为无效线索
        saveUnuse() {
            // this.$refs.clueForm.resetFields();
            this.clearClueForm();
            // 显示线索弹框
            this.$refs.clueFeedback.init(3, this.clueInfo.newClient);
        },
        // 提交跟进
        submitFollow(obj) {
            console.log('this.searchPage.clueId', this.searchPage.id);
            this.clueForm.clueId = this.searchPage.id;
            this.clueForm.clueFollowResult = 1;
            this.clueForm.purposeLevel = obj.purposeLevel;
            this.clueForm.qualityLevel = obj.qualityLevel;
            this.clueForm.callUUID = this.$store.state.callStatus.callUUID;
            // this.clueForm.tag = "";
            // console.log(typeof this.clueForm.residence == string);
            if (typeof this.clueForm.residence == 'object') {
                this.clueForm.residence =
                    this.clueForm.residence[this.clueForm.residence.length - 1];
                console.log(this.clueForm.residence);
            }
            this.clueForm.city = this.clueForm.residence;
            console.log(this.clueForm);
            clue_followed_log(this.clueForm).then((res) => {
                if (res.code == 0) {
                    this.clueInfo.newClient == 1
                        ? this.$message.success('已保存为我的客户')
                        : this.$message.success('已保存跟进内容');
                    this.$refs.clueFeedback.closeFollow();
                    this.$refs.clueForm.resetFields();
                    // 调用下一条
                    if (this.previousOrNext.next) {
                        this.nextPage();
                    } else {
                        // 如果下一页的id存在就跳到下一页没有的话关闭
                        this.goback();
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 未确认确定按钮
        saveUnsure(val) {
            this.clueForm.clueId = this.searchPage.id;
            this.clueForm.clueFollowResult = 2;
            this.clueForm.tag = val;
            this.clueForm.city = this.clueForm.residence;
            clue_followed_log(this.clueForm).then((res) => {
                if (res.code == 0) {
                    this.$message.success('已加入未确认线索');
                    this.$refs.clueFeedback.closeUnconfirmed();
                    this.$refs.clueForm.resetFields();
                    // 调用下一条
                    if (this.previousOrNext.next) {
                        this.nextPage();
                    } else {
                        // 如果下一页的id存在就跳到下一页没有的话关闭
                        this.goback();
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 点击无效线索确定按钮
        saveUnusertag(val) {
            this.clueForm.clueId = this.searchPage.id;
            this.clueForm.clueFollowResult = 3;
            this.clueForm.tag = val;
            this.clueForm.city = this.clueForm.residence;
            clue_followed_log(this.clueForm).then((res) => {
                if (res.code == 0) {
                    this.$message.success('已丢入无效列表');
                    this.$refs.clueFeedback.closeUnuser();
                    this.$refs.clueForm.resetFields();
                    // 调用下一条
                    if (this.previousOrNext.next) {
                        this.nextPage();
                    } else {
                        // 如果下一页的id存在就跳到下一页没有的话关闭
                        this.goback();
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 操作记录
        operation(type) {
            if (type == 1)
                this.$refs.operationInfo.showDetail(this.clueInfo, 1);
            if (type == 2)
                this.$refs.operationInfo.showDetail(this.clueInfo, 2);
        },
        //  呼叫事件处理
        callEventNameHandle(val) {
            this.call_SatausChange = val;
            console.log(val, 'fuckval');
        },
    },
};
</script>

<style lang="less" scoped>
@import '@/css/table.less';

.flex {
    display: flex;
    align-items: center;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.el-row {
    height: 100%;

    .el-col {
        height: 100%;

        .el-card {
            height: 100%;

            /deep/ .el-card__body {
                height: 100%;
            }
        }
    }
}

.clue-follow-page {
    padding: 10px;
    height: 100%;

    // 左侧
    .left-content {
        height: 100%;

        .title-tip {
            font-weight: 400;
            padding-bottom: 5px;
            border-bottom: 1px solid rgba(220, 223, 230, 1);

            .title-tip-tit {
                font-size: 16px;
            }

            .title-tip-option {
                font-size: 14px;
                color: #02a7f0;
                cursor: pointer;
            }
        }

        .left-content-list {
            height: 100%;
            overflow-y: hidden;
            border: 1px solid rgba(220, 223, 230, 1);
            margin: 10px 0;
            border-radius: 4px;

            .list-item {
                overflow-y: scroll;
                height: 100%;
                padding: 20px;

                .mobile {
                    color: red;
                    font-size: 18px;
                }

                .info-item {
                    font-size: 14px;
                    color: #555555;
                    padding: 10px 0px;
                    border-bottom: 1px solid #f2f2f2;
                }

                .remark {
                    padding: 10px 0px;

                    // border: 1px solid black;
                    &::after {
                        content: '';
                        border-bottom: 1px solid #ccc;
                        width: 100px;
                    }

                    .item-remark {
                        font-size: 14px;
                        background: #fafafa;
                        min-height: 100px;
                        padding: 15px;
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }

                    .re-title {
                        color: rgb(85, 85, 85);
                        font-size: 13px;
                        font-weight: 700;
                    }
                }
            }

            .time-change {
                padding: 10px;
                border-top: 1px solid #f2f2f2;
                font-size: 12px;
            }
        }

        .left-btn {
            color: #02a7f0;
            cursor: pointer;
            font-size: 14px;
        }
    }

    // 右侧区域
    .right-content {
        height: 100%;

        .title-tip {
            font-weight: 400;
            padding-bottom: 5px;
            border-bottom: 1px solid rgba(220, 223, 230, 1);

            .title-tip-tit {
                font-size: 16px;
            }

            .title-tip-option {
                font-size: 14px;
                color: #02a7f0;
                cursor: pointer;
            }
        }

        .right-content-list {
            overflow-y: scroll;
            height: 100%;

            .title-font {
                background-color: rgba(249, 249, 249, 1);
                margin-top: 15px;
                padding: 10px;

                .base-info {
                    margin-left: 5px;
                    cursor: pointer;
                    // &:active {
                    //   animation: spin 1s linear infinite;
                    // }
                }
            }
        }

        // @keyframes spin {
        //   from {
        //     transform: rotate(0deg);
        //   }
        //   to {
        //     transform: rotate(360deg);
        //   }
        // }
    }
}

.switch-area {
    height: 50px !important;
    line-height: 50px;
    padding-left: 26px;
    border: 1px solid #ccc;
    border-bottom: none;
}

.info-area {
    border: 1px solid #ccc;
    border-left: 0;
    border-bottom: none;
    line-height: 50px;
    height: 50px !important;
}

/deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 5px;
}

/deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
    color: #4086ec;
    background-color: #ecf3fd;
    box-shadow: 0 0px 0px #ccc;
    border-color: #dcdfe6;
}

/deep/ .el-checkbox-button.is-focus .el-checkbox-button__inner {
    border-color: #dcdfe6;
}

/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #4086ec;
    background-color: #ecf3fd;
    box-shadow: 0 0px 0px #ccc;
    border-color: #dcdfe6;
}

/deep/ .el-form-item__content .el-input-group {
    vertical-align: baseline;
}

/deep/ .el-input-group__append {
    padding: 0 10px;
}

// .el-form-item {
//   margin-top: 20px;
//   margin-bottom: 15px;
// }
.prop-center {
    margin-top: 10px;
    margin-bottom: 0;
}

.xin {
    font-size: 25px;
    vertical-align: middle;
    color: #d7d7d7;
}

.ping {
    color: #ff8190;
}

.normal {
    color: #4086ec;
    background-color: rgba(236, 243, 253, 1);
    border-color: rgba(160, 195, 246, 1);

    &:active {
        background-color: rgba(242, 247, 254, 1);
        border-color: rgba(189, 213, 249, 1);
    }

    &:hover {
        background-color: #409eff;
        color: #fff;
    }
}

/deep/ .el-form-item__content {
    text-align: left;
}

/deep/ .el-form-item__error {
    min-width: 143px;
}

/deep/ .el-input-group__append {
    padding: 0 10px;
}

/deep/ .el-input__inner {
    padding-right: 0;
}

.buttonCallPhone {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .buttonCALL {
        width: 120px;
        height: 32px;
        background-color: rgba(64, 134, 236, 1);
        border-radius: 3px;
        color: white;
        text-align: center;
        line-height: 32px;
    }
}

.buttonCALL:active {
    opacity: 0.5;
}
</style>
